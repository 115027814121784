import { isProd } from 'common/utils/common';
import type { User } from 'modules/profile/utils/profileApiUtils';
import OneSignalReact from 'react-onesignal';

export type OneSignalHashes = {
  id: string;
  email: string;
};

export const init = (): void => {
  OneSignalReact.init({
    appId: isProd() ? '704ff839-ecb3-48be-bfd8-23ee84b0075f' : 'a69ce4ea-e90d-4e5c-8088-0b31cb25818e',
    safari_web_id: 'web.onesignal.auto.09714a24-a3bb-414f-8109-d75a4f07e6fa',
    autoResubscribe: true,
    notifyButton: {
      enable: true,
    },
  });
};

export const logInUser = async (
  user: User,
  isNewUser: boolean | undefined,
): Promise<void> => {
  if (isNewUser) {
    await OneSignalReact.User.addEmail(user.email);
    await OneSignalReact.User.addTags({
      real_name: user.name || user.username,
    });
  }
  await OneSignalReact.login(`${user.id}`);
};

import '../../wdyr'; // <--- first import
import { initMixpanel } from 'common/utils/mixpanel';

import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toggle/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import fbPixel from 'common/utils/facebook-pixel';
import * as OneSignal from 'common/utils/onesignal';

import { createFirebaseApp } from 'common/utils/firebase';

interface Props {
  pageProps: any;
  Component: any;
}

export const App = (props: Props) => {
  const router = useRouter();
  const { Component, pageProps } = props;

  useEffect(() => {
    OneSignal.init();
    initMixpanel();
    createFirebaseApp();
  }, []);

  useEffect(() => {
    const isProd = window.location.hostname.search('stoicbible.com') !== -1;

    const handleRouteChange = () => {
      if (process.env.NODE_ENV === 'production' && isProd) {
        fbPixel.logPageView();
      }
    };

    if (process.env.NODE_ENV === 'production' && isProd) {
      fbPixel.logPageView();
    }

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <DefaultSeo
        title="Stoic Bible - Stoicism in One Place"
        description="Stoic Bible will help you become wiser, more tranquil and resilient by
          making Stoicism easy for you to learn and practice every day."
        openGraph={{
          type: 'website',
          locale: 'en_IE',
          url: 'https://www.stoicbible.com',
          site_name: 'Stoic Bible',
          title: 'Stoic Bible - Stoicism in One Place',
          description: 'Stoic Bible will help you become wiser, more tranquil and resilient by making Stoicism easy for you to learn and practice every day.',
          images: [
            {
              url: '/Stoic Bible Logo Mark.svg',
              width: 800,
              height: 600,
              alt: 'Stoic Bible',
            },
          ],
        }}
        twitter={{
          handle: '@stoicbible',
          site: '@stoicbible',
          cardType: 'summary',
        }}
      />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
      />
      <Component {...pageProps} />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        draggable={false}
        closeOnClick
        pauseOnHover
      />
    </>
  );
};

export default App;

import { isProd } from 'common/utils/common';
import type { Dict } from 'mixpanel-browser';
import mixpanel from 'mixpanel-browser';

export const initMixpanel = (): void => {
  if (typeof window !== 'undefined') {
    if (isProd()) {
      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN as string);
    } else {
      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN_DEV as string);
    }
  }
};

const actions = {
  identifyUser: (id: string): void => {
    mixpanel.identify(id);
  },

  trackEvent: (name: string, props?: Dict): void => {
    mixpanel.track(name, props);
  },

  people: {
    set: (props: Dict): void => {
      mixpanel.people.set(props);
    },
  },
};

export const eventNames = {
  view_landing_page: 'View Landing Page',

  view_sign_up_modal: 'View Sign Up Modal',
  view_sign_in_modal: 'View Sign In Modal',
  sign_up: 'Sign Up',
  delete_account: 'Delete Account',
  log_in: 'Log In',
  log_out: 'Log Out',

  view_membership_page: 'View Membership Page',
  open_subscription_form: 'Open Subscription Form',
  enter_card_details: 'Enter Card Details',
  purchase_subscription: 'Purchase Subscription',

  change_reader_book: 'Change Reader Book',
  view_chapter: 'View Chapter',

  add_highlight: 'Add Highlight',
  change_highlight_color: 'Change Highlight Color',
  save_bookmark: 'Save Bookmark',
  share_text_on_social: 'Share Text on Social',

  open_reader_settings: 'Open Reader Settings',
  change_reader_font_size: 'Change Reader Font Size',
  change_reader_font_family: 'Change Reader Font Family',
  change_reader_verse_numbers: 'Change Reader Verse Numbers',

  view_thought_editor: 'View Thought Editor',
  add_thought: 'Add Thought',
  update_thought: 'Update Thought',
  view_thought: 'View Thought',
  view_journals_page: 'View Journals Page',

  change_theme: 'Change Theme',

  open_search_box: 'Open Search Box',
  perform_search: 'Perform Search',

  view_all_books_page: 'View All Books Page',
  view_book_page: 'View Book Page',

  view_faq_page: 'View FAQ Page',

  view_user_tab: 'View User Tab',
  view_home_page: 'View Home Page',
};

export const Mixpanel = actions;

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

const isProd = process.env.NODE_ENV === 'production';

const logPageView = () => {
  if (isProd) {
    window.fbq('track', 'PageView');
  }
};

const logLead = () => {
  if (isProd) {
    window.fbq('track', 'Lead', {
      content_category: 'Auth',
      content_name: 'Sign Up Page',
    });
  }
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
const logEvent = (name, options = {}) => {
  if (isProd) {
    window.fbq('track', name, options);
  }
};

export const pixelSnippet = `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', ${FB_PIXEL_ID});
`;

export default {
  logPageView,
  logEvent,
  logLead,
};
